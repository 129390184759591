<template>
  <base-section
    id="info-recertificacion"
    space="0"
  >
    <v-container
      class="py-0"
      fluid
    >
      <v-row justify="center">

        <v-col cols="12">

        </v-col>
        <v-theme-provider dark>
          <v-col
            class="accent extra-padding"
            cols="12"
            md="4"
          >
            <v-responsive
              class="mx-auto"
              max-width="360"
            >
              <base-subtitle
                class="primary--text"
                title=""
                space="2"
              />

              <base-title
                mobile-size="title"
                size="display-1"
                space="2"
                title="Información"
              />

              <base-divider color="primary" />

              <base-body :space="$vuetify.breakpoint.smAndDown ? 0 : undefined">
                Para presentar tu solicitud de Recertificación, debes ingresar a tu cuenta COMPAC desde el siguiente botón
              </base-body>

              <base-btn
                :href="path_to_certificaciones"
                outlined
                color="text--white"
                target="_blank"
              >
                INGRESAR A MI CUENTA COMPAC
              </base-btn>



            </v-responsive>
          </v-col>
        </v-theme-provider>

        <v-col
          cols="12"
          md="8"
        >
          <h2 class="font-weight-bold mb-0 text-center red--text text-center mb-4" >
            <!--Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C.-->
            Lista de Patólogos Clínicos Certificados

          </h2>

          <div class="font-weight-bold" style="text-align: justify; color: #636363">
           <p class="text-center"> Puedes consultar la lista de Patólogos Clínicos Certificados  dando click al siguiente botón.</p>
           <div align="center" class="mt-7">
             <base-btn
               :color="!theme.isDark ? 'accent' : 'white'"
               to="medicos"
               outlined
             >
               CONSULTAR PATÓLOGOS CERTIFICADOS
             </base-btn>
           </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInfoRecertificacion',
    inject: ['theme'],


    components: {
      Projects: () => import('@/views/sections/Projects'),
    },

    data: () => ({
      category: 'All',
      path_to_certificaciones:process.env.VUE_APP_CERTIFICACIONES_URL + '/login',
      categories: [
        'All',

      ],
    }),

    methods: {
      filter (card) {
        return card.category === this.category || this.category === 'All'
      },
    },
  }
</script>
